import { MyCreativeQuickviewModalProps } from './types';
import { useEffect, useState } from 'react';
import { CreativeType, VideoMeta } from 'types/components/creatives';
import MyCreativeQuickviewPreviewView from './MyCreative.Quickview.Preview.View.tsx';

const MyCreativeQuickviewPreview = (props: MyCreativeQuickviewModalProps) => {
  const { ad, open } = props;
  const [preview, setPreview] = useState<string | null>(null);
  const [meta, setMeta] = useState<VideoMeta | null>(null);

  const isVideo = ad?.creativeType === CreativeType.VIDEO;

  useEffect(() => {
    if (!open) {
      window.setTimeout(() => setPreview(null), 250);
      return;
    }

    const getVideoSource = () =>
      ad?.creativeAssets?.find((asset) => asset.videoMeta?.source)?.videoMeta;

    if (ad) {
      let previewLink = ad.creativeAssets?.at(0)?.permalinkUrl ?? null;
      if (isVideo) {
        const videoMeta = getVideoSource();
        const source = meta?.source;

        setMeta(videoMeta ?? null);

        if (!source) {
          const FB_PERMALINK_URL =
            'https://www.facebook.com/plugins/video.php?href=https://www.facebook.com';
          previewLink = ad.creativeAssets?.at(0)?.permalinkUrl ?? null;
          previewLink = `${FB_PERMALINK_URL}${previewLink}`;
        } else {
          previewLink = source;
        }
      }

      setPreview(previewLink);
    }
  }, [ad, open, isVideo]);

  const openInNewTab = isVideo && ad?.creativeAssets?.every((asset) => !asset.videoMeta?.source);

  const onOpenTabForVideo =
    openInNewTab && preview
      ? () => {
          window
            .open(
              preview,
              'targetWindow',
              `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,height=700,width=400,rel="noreferrer noopener"`
            )
            ?.focus();
        }
      : undefined;

  return (
    <MyCreativeQuickviewPreviewView
      {...props}
      meta={meta}
      previewUrl={preview}
      loading={false}
      isVideo={isVideo}
      onClickForVideo={onOpenTabForVideo}
    />
  );
};

export default MyCreativeQuickviewPreview;
