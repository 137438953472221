import { useHandleApi } from 'hooks';
import { useParams } from 'react-router-dom';

const useCreativeBreakdown = () => {
  const { brandId } = useParams();
  const { handleApi, loading } = useHandleApi();

  // TODO (MM): remove when SDK is updated
  const getCreativeBreakdown = async () => {
    const endpoint = `/brand/${brandId}/fb/ads/creatives/breakdown`;

    return handleApi({ endpoint, brandId });
  };

  return {
    getCreativeBreakdown,
    loading,
  };
};

export default useCreativeBreakdown;
