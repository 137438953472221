export const CATEGORIES: { [key: string]: string } = {
  BEAU: 'Beauty',
  CHIL: 'Children',
  FASH: 'Fashion & Accessories',
  FOOD: 'Food & Drink',
  HEAL: 'Health',
  HOBB: 'Hobbies & Leisure',
  HOME: 'Home',
  OTHE: 'Other',
  PETS: 'Pets',
  TRAV: 'Travel',
} as const;
