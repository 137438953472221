import styled from '@emotion/styled';
import { Button, colors, LabelStatus, PrivacyOnSmall } from '@innovationdepartment/proxima-ui';

const CustomButton = styled(Button)`
  max-width: 48px;
  padding: 4px 12px;
  min-width: unset;
  border-color: ${colors.neutral10};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TileFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
`;

type MyCreativeTilesTileFooterProps = {
  adStatus: string;
};

const MyCreativeTilesTileFooter = (props: MyCreativeTilesTileFooterProps) => {
  const { adStatus } = props;

  const status = adStatus === 'ACTIVE' ? 'Active' : 'Inactive';
  const statusType = adStatus === 'ACTIVE' ? 'success' : 'error';

  const onButtonClick = () => {};
  return (
    <TileFooter>
      <ButtonGroup>
        <CustomButton
          label={undefined}
          onClick={onButtonClick}
          color="secondary"
          variant="outlined"
        >
          <PrivacyOnSmall />
        </CustomButton>
      </ButtonGroup>
      <LabelStatus label={status} variant={statusType} />
    </TileFooter>
  );
};

export default MyCreativeTilesTileFooter;
