import styled from '@emotion/styled';
import { colors, MagicStars, Text } from '@innovationdepartment/proxima-ui';

const GradientTag = styled.div`
  border-radius: 100px;
  background: linear-gradient(90deg, ${colors.proximaGreen40} 0%, #89d0e9 100%);
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 4px 8px;
  width: 67px;
`;

const HeaderContainer = styled.div`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.neutral20};
  display: flex;
  height: 69px;
  padding: 12px 32px;
  justify-content: space-between;
  align-items: center;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const IconLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;

  > svg {
    height: 16px;
    width: 16px;
  }
`;

type MyCreativeHeaderProps = {
  brandName: string;
  title: string;
};

const MyCreativeHeader = (props: MyCreativeHeaderProps) => {
  const { brandName, title } = props;

  return (
    <HeaderContainer>
      <HeaderSection>
        <Text color="neutral90" variant="h4" isTermina>
          {title} | {brandName}
        </Text>
        <GradientTag>
          <IconLabelContainer>
            <MagicStars />
            <Text variant="tag2Semibold" color="neutral80">
              Beta
            </Text>
          </IconLabelContainer>
        </GradientTag>
      </HeaderSection>
    </HeaderContainer>
  );
};

export default MyCreativeHeader;
