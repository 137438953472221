import styled from '@emotion/styled';
import { Button, colors, Text } from '@innovationdepartment/proxima-ui';
import { TopAdsTabsProps } from '../../types';
import { PROXIMA_SHARE_FEEDBACK_URL } from 'constants/urls';
import LoadingBar from 'ui/LoadingBar';

const TabsContainer = styled.div`
  height: 60px;
  border-bottom: 1px solid ${colors.neutral20};
  position: relative;

  & .loading-bar {
    position: absolute;
    bottom: -5px;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  height: 100%;
  padding: 18px;
`;

const StyledText = styled(Text)`
  display: flex;
  line-height: 24px;
`;

const TopAdsTabs = (props: TopAdsTabsProps) => {
  const { loading } = props;

  return (
    <TabsContainer>
      <TabsWrapper className="top-ads-tabs">
        <StyledText variant="body1">✨ All Top Ads</StyledText>
        <Button
          label="Share Feedback"
          overrideColors={{
            backgroundColor: 'linear-gradient(to right, #45EDBA, #89D0E9);',
            textColor: 'neutral100',
          }}
          onClick={() =>
            window.open(PROXIMA_SHARE_FEEDBACK_URL, '_blank', 'rel="noreferrer noopener"')?.focus()
          }
        />
      </TabsWrapper>
      <LoadingBar className="loading-bar" loading={loading} />
    </TabsContainer>
  );
};

export default TopAdsTabs;
