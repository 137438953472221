import styled from '@emotion/styled';
import { colors, HelpCircled, Text, Tooltip } from '@innovationdepartment/proxima-ui';
import MyCreativeBreakdownPie from './MyCreative.Breakdown.Pie';
import MyCreativeBreakdownTable from './MyCreative.Breakdown.Table';
import { MyCreativeBreakdownContainerProps } from '../../types';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0 16px;
`;

const Container = styled.div`
  background-color: ${colors.white};
  border: 2px solid ${colors.neutral10};
  border-radius: 8px;
  margin: 20px 32px;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DataWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  gap: 16px;
  padding: 16px;
  width: 100%;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 2px;

  & :is(svg, path) {
    fill: ${colors.neutral60};
    height: 20px;
    width: 20px;
  }
`;

const CREATIVE_BREAKDOWN_TOOLTIP_CONTENT = (
  <div style={{ maxWidth: 220, padding: '8px 12px' }}>
    <Text variant="body2Semibold" color="white">
      A breakdown of spend and performance across various creative types during the selected
      timeframe.
    </Text>
  </div>
);

const MyCreativeBreakdownContainer = (props: MyCreativeBreakdownContainerProps) => {
  const { chartData, tableData } = props;

  return (
    <Container>
      <Header>
        <Text variant="h6" isTermina={false}>
          Creative Breakdown
        </Text>
        <InfoWrapper>
          <Tooltip arrow size="small" title={CREATIVE_BREAKDOWN_TOOLTIP_CONTENT}>
            <TooltipWrapper>
              <HelpCircled />
            </TooltipWrapper>
          </Tooltip>
          <Text variant="body2Semibold">What is this?</Text>
        </InfoWrapper>
      </Header>
      <DataWrapper>
        <MyCreativeBreakdownPie
          activeAds={chartData.totalActiveAds}
          carouselAds={chartData.carousel}
          staticAds={chartData.static}
          videoAds={chartData.video}
        />
        <MyCreativeBreakdownTable data={tableData} />
      </DataWrapper>
    </Container>
  );
};

export default MyCreativeBreakdownContainer;
