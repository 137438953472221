// copied from Nova
import { BrandItem } from '@innovationdepartment/proxima-sdk-axios';
import { AdData, StateData } from './fbTable';

export type CreativeAsset = {
  id: number;
  brandId: string;
  adAccountId: string;
  permalinkType: string;
  permalinkUrl: string;
  createdAt: string;
  updatedAt: string;
  assetId: string | null;
  videoMeta?: VideoMeta | undefined;
};

export enum CreativeType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  CAROUSEL = 'CAROUSEL',
}

export type CreativeAd = StateData<
  AdData['data'][number] & {
    brand?: BrandItem;
    loading?: boolean;
    category?: string;
    launchDate?: string;
    spendDays?: string;
    lifetimeSpend?: string;
    linkUrl: string;
    ctr: number;
    cpm: number;
    cpc: number;
    cvr: number;
    purchaseRoasCategoryQuentile: number;
    ctrCategoryQuentile: number;
    cpcBrandIdQuentile: number;
    cpmBrandIdQuentile: number;
    cvrBrandIdQuentile: number;
    purchaseRoasBrandIdQuentile: number;
    ctrBrandIdQuentile: number;
    cpaCategoryQuentile: number;
    cpaBrandQuentile: number;
    cpaBrandIdQuentile: number;
    thumbstopRate?: number;
    thumbstopRateBrandIdQuentile?: number;
    creativeType?: CreativeType;
    creativeAssets?: CreativeAsset[];
  }
>['data'][0];

/* copied from proxima.integrations.fb.api */
export type VideoMeta = {
  length: number;
  source?: string;
  created_time: string;
  id: string;
  published: boolean;
  title?: string;
  description?: string;
  permalink_url?: string;
  picture?: string;
  thumbnails?: {
    height: number;
    width: number;
    id: string;
    uri: string;
    is_preferred: boolean;
    scale: number;
  }[];
};

export type CreativeAdSummary = {
  costPerResult: number;
  costPerResultLabel: string;
  cpc: number;
  impressions: number;
  spend: number;
};
