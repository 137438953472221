import styled from '@emotion/styled';
import { colors, Text } from '@innovationdepartment/proxima-ui';
import Highcharts from 'highcharts';
import PieChart from 'highcharts-react-official';

const PieWrapper = styled.div`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
`;

type MyCreativeBreakdownPieProps = {
  activeAds: number;
  videoAds: number;
  staticAds: number;
  carouselAds: number;
};

const MyCreativeBreakdownPie = (props: MyCreativeBreakdownPieProps) => {
  const { activeAds, videoAds, staticAds, carouselAds } = props;

  // TODO(MM): maybe move to the UI lib
  const pieChartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: undefined,
      borderWidth: undefined,
      plotShadow: false,
      type: 'pie',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        type: 'pie',
        name: '',
        data: [
          {
            name: 'Video',
            y: videoAds,
            color: colors.teal30,
          },
          {
            name: 'Static',
            y: staticAds,
            color: colors.purple30,
          },
          {
            name: 'Carousel',
            y: carouselAds,
            color: colors.orange30,
          },
        ],
      },
    ],
    title: undefined,
    tooltip: {
      enabled: false,
    },
  };

  return (
    <PieWrapper>
      <Text variant="body2Semibold">{activeAds} Active Ads</Text>
      <PieChart
        highcharts={Highcharts}
        options={pieChartOptions}
        containerProps={{ style: { height: '180px', width: '206px' } }}
      />
    </PieWrapper>
  );
};

export default MyCreativeBreakdownPie;
