import { CreativeAd } from 'types/components/creatives';
import { MyCreativeSortBy } from '../types';

const MAX_AD_NAME_LENGTH = 30;

export const truncateText = (text: string) =>
  text.length > MAX_AD_NAME_LENGTH ? `${text.slice(0, MAX_AD_NAME_LENGTH)}...` : text;

export const sortAdsBySpend = (ads: CreativeAd[], sortBy: MyCreativeSortBy) => {
  if (sortBy === MyCreativeSortBy.HIGHESTSPEND) {
    const highestSpendSortedAds = [...ads].sort((ad1, ad2) => ad2.spend - ad1.spend);

    return highestSpendSortedAds;
  }
  const lowestSpendSortedAds = [...ads].sort((ad1, ad2) => ad1.spend - ad2.spend);

  return lowestSpendSortedAds;
};
