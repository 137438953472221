import { PropsOf } from '@emotion/react';
import {
  // TODO(Jenky): Disabled on 03/22/2023
  // ManageIcon,
  IntegrationsIcon,
  Group as AudiencesIcon,
  NavbarMenu,
  Settings,
  AvatarImagePlaceholder,
  Finance as Performance,
  LinkIcon,
  CameraShutter,
} from '@innovationdepartment/proxima-ui';
import { Insights } from '@mui/icons-material';
import { Brand } from 'types/stores/brand-store';
import LoadingSpinner from 'ui/LoadingSpinner';
import renderNavbarItem from './renderNavbarItem';
import { MAX_BRANDS_HISTORY } from 'constants/brandsHistory';
import { NavigateFunction } from 'react-router-dom';
import { FeatureFlags } from 'types/featureFlag';
import NavBarView from '../NavBar.View';

type GetNavbarTopButtonsProps = {
  brandId: string;
  navigate: NavigateFunction;
  featureFlags: FeatureFlags;
};
export const getNavbarTopButtons = (props: GetNavbarTopButtonsProps) => {
  const { brandId, featureFlags, navigate } = props;
  const { benchmarks, creatives, myCreative } = featureFlags;

  const showInsightsButton = benchmarks || creatives || myCreative;

  const creativeButtons = {
    items: [
      {
        label: 'Explore Top Ads',
        pathname: `/brand/${brandId}/insights/explore-ads`,
      },
    ],
    label: 'CREATIVE',
    icon: <CameraShutter />,
  };

  // pushes My Creative button if the feature flag is enabled
  // if we don't do this, it leaves an empty element below the last button under Creatives in the nav drawer
  if (myCreative) {
    creativeButtons.items.push({
      label: 'My Creative',
      pathname: `/brand/${brandId}/insights/my-creative`,
    });
  }

  const insightsButton = showInsightsButton && {
    button: { icon: <Insights />, label: 'Insights' },
    isActive: false,
    drawerItems: {
      items: [
        creatives && creativeButtons,
        benchmarks && {
          items: [
            {
              label: 'Trends',
              pathname: `/brand/${brandId}/insights/trends`,
            },
            {
              label: 'Benchmarks',
              pathname: `/brand/${brandId}/insights/chart`,
            },
          ],
          label: 'PERFORMANCE',
          icon: <Performance />,
        },
      ].filter(Boolean),
      onClick: (pathname: string) => navigate(pathname),
      title: 'Insights',
    },
  };

  return [
    {
      label: 'Audiences',
      pathname: `/brand/${brandId}/audiences`,
      icon: <AudiencesIcon />,
    },
    // TODO(Jenky): Disabled on 03/22/2023
    // {
    //   label: 'Manage',
    //   pathname: '/brand/${brandId}/manage',
    //   icon: <ManageIcon />,
    // },
    insightsButton,
    {
      label: 'Integrations',
      pathname: `/brand/${brandId}/integrations`,
      icon: <IntegrationsIcon />,
    },
  ].filter(Boolean) as PropsOf<typeof NavBarView>['navbarTopButtons'];
};

export const getNavbarBottomButtons = ({
  loading,
  brands,
  brand,
}: {
  loading: boolean;
  brands: Brand[];
  brand: Brand;
}) => {
  type NavbarMenuItem = NonNullable<PropsOf<typeof NavbarMenu>['items']>[number] & {
    noTooltip?: true;
  };

  const brandsHistoryItems: NavbarMenuItem[] = [];

  brandsHistoryItems.push(
    ...([
      /* brand name title */
      {
        label: brand.name,
        value: brand.brandId,
        disableHover: true,
        active: true,
        icon: <AvatarImagePlaceholder />,
        trailingDivider: true,
      },
      /* brand settigs route */
      {
        label: 'Brand settings',
        value: `/brand/${brand.brandId}/settings/general`,
        icon: <Settings />,
        trailingDivider: true,
        noTooltip: true,
      },
    ] satisfies NavbarMenuItem[])
  );

  if (loading) {
    brandsHistoryItems.push({
      label: '',
      value: 'spinner',
      icon: <LoadingSpinner />,
      trailingDivider: true,
      noTooltip: true,
    });
  } else {
    brands
      /* filter own brand */
      .filter((brandItem) => brandItem.brandId !== brand.brandId)
      .slice(0, MAX_BRANDS_HISTORY)
      .forEach((brandItem) => {
        brandsHistoryItems.push({
          label: brandItem.name,
          value: brandItem.brandId,
          icon: <AvatarImagePlaceholder />,
          trailingDivider: brandItem.brandId === brands.at(-1)?.brandId,
        });
      });
  }

  brandsHistoryItems.push({
    label: 'Manage all brands',
    value: '/brands',
    icon: <LinkIcon />,
    noTooltip: true,
  });

  return [
    {
      menuIcon: <Settings data-testid="Manage brand" />,
      items: brandsHistoryItems,
      key: 'brand-settings',
      label: '',
      render: renderNavbarItem,
    },
  ] satisfies PropsOf<typeof NavbarMenu>[];
};
