import styled from '@emotion/styled';
import { CustomCard, Divider, Text } from '@innovationdepartment/proxima-ui';
import InfiniteScroll from 'ui/InfiniteScroll';
import { MyCreativeTilesTileProps } from '../../types';
import MyCreativeTilesTileMetrics from './MyCreative.Tiles.Tile.Metrics';
import { truncateText } from '../helpers';
import MyCreativeTilesTileFooter from './MyCreative.Tiles.Tile.Footer';

const BodyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const MyCreativeTilesTile = (props: MyCreativeTilesTileProps) => {
  const { ad, thumbnail, shouldShowInfiniteScroll, onTileClick, onFetchNext, tag } = props;

  return (
    <>
      <CustomCard
        key={ad.adId}
        size="tile2"
        onClick={() => onTileClick(ad)}
        bodyContent={
          <BodyContentWrapper>
            <TitleWrapper>
              <Text variant="body2Medium">{truncateText(ad?.name || '')}</Text>
            </TitleWrapper>
            <MyCreativeTilesTileMetrics ad={ad} />
            <Divider margin={0} />
            <MyCreativeTilesTileFooter adStatus={ad.status} />
          </BodyContentWrapper>
        }
        label={undefined}
        headerProps={{
          heroUrl: thumbnail,
          tag,
        }}
      />
      {shouldShowInfiniteScroll && <InfiniteScroll onInfiniteScroll={onFetchNext} />}
    </>
  );
};

export default MyCreativeTilesTile;
