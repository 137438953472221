import myCreativeBreakdownRowRenderer from './myCreativeBreakdownRowRenderer';
import { Table, TableProps } from '@innovationdepartment/proxima-ui';
import { MyCreativeBreakdownRow, MyCreativeBreakdownTableProps } from '../../types';
import styled from '@emotion/styled';

const TableContainer = styled.div`
  > div:has(table) {
    border-radius: 0;
    outline: unset;

    & td,
    & th {
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }

  border-width: 0;
  width: 100%;
`;

const MyCreativeBreakdownTable = (props: MyCreativeBreakdownTableProps) => {
  const { data } = props;

  const config: TableProps<MyCreativeBreakdownRow>['config'] = {
    columns: {
      order: ['creativeType', 'percentOfSpend', 'roas', 'ctr', 'cpm', 'cpc', 'thumbstop'],
      creativeType: {
        label: 'Creative Type',
        width: 100,
      },
      percentOfSpend: {
        label: '% of Spend',
        width: 100,
      },
      roas: {
        label: 'ROAS',
        width: 100,
      },
      ctr: {
        label: 'CTR',
        width: 100,
      },
      cpm: {
        label: 'CPM',
        width: 100,
      },
      cpc: {
        label: 'CPC',
        width: 100,
      },
      thumbstop: {
        label: 'Thumbstop',
        width: 100,
      },
    },
    cellRenderer(row) {
      return myCreativeBreakdownRowRenderer({ ...row });
    },
    // eslint-disable-next-line
    emptyStateRenderer() {
      return '';
    },
  };
  return (
    <TableContainer>
      <Table data={data} config={config} variant="celes" />
    </TableContainer>
  );
};

export default MyCreativeBreakdownTable;
