import styled from '@emotion/styled';
import { colors, Divider, Text } from '@innovationdepartment/proxima-ui';
import { MyCreativeQuickviewModalProps } from './types';
import MyCreativeQuickviewPerformanceBreakdown from './MyCreative.Quickview.PerformanceBreakdown';
import MyCreativeQuickviewSubdetails from './MyCreative.Quickview.Subdetails';

const DetailsContentWrapper = styled.div`
  background-color: ${colors.white};
  min-width: 472px;
  height: 100%;
  padding: 32px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  & > :first-child {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const MyCreativeQuickviewAdDetails = (props: MyCreativeQuickviewModalProps) => {
  const { ad } = props;

  return (
    <DetailsContentWrapper>
      <DetailsWrapper>
        <Text variant="body1Semibold" color="neutral90">
          {ad?.name}
        </Text>
      </DetailsWrapper>
      <Divider margin={16} />
      <MyCreativeQuickviewSubdetails ad={ad} />
      <MyCreativeQuickviewPerformanceBreakdown ad={ad} />
    </DetailsContentWrapper>
  );
};

export default MyCreativeQuickviewAdDetails;
