import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';

type QuintilePillProps = {
  quintile: number;
};

type QuintileOptions = {
  label: string;
  pillColor: string;
  dotColor: string;
};

const QuintileMap: Record<number, QuintileOptions> = {
  0: { label: 'Worst', pillColor: '#F9DAD7', dotColor: '#E14436' },
  1: { label: 'Below', pillColor: '#FBEED7', dotColor: '#EBA839' },
  2: { label: 'Avg', pillColor: '#FCF6D4', dotColor: '#EFD429' },
  3: { label: 'Great', pillColor: '#EBF5D7', dotColor: '#9DCD37' },
  4: { label: 'Best', pillColor: '#CCEFD5', dotColor: '#00AD2E' },
};

const QuintilePillWrapper = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 100px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 64px;
`;

const QuintileDot = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 100px;
  height: 10px;
  width: 10px;
`;

const QuintilePill = (props: QuintilePillProps) => {
  const { quintile } = props;

  const quintileOptions = QuintileMap[quintile];

  return (
    <QuintilePillWrapper backgroundColor={quintileOptions.pillColor}>
      <QuintileDot backgroundColor={quintileOptions.dotColor} />
      <Text variant="tag2Semibold">{quintileOptions.label}</Text>
    </QuintilePillWrapper>
  );
};

export default QuintilePill;
