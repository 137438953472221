import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import { MyCreativeQuickviewModalProps } from './types';
import LoadingSpinner from 'ui/LoadingSpinner';
import VideoPlayer from 'ui/VideoPlayer';
import { VideoMeta } from 'types/components/creatives';

const PreviewContentWrapper = styled.div<{ clickable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 454px;
  height: 525px; /* huh? */
  background-color: ${colors.neutral0};
  padding: 32px;

  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
`;

const ThumbnailWrapper = styled.div<{ src?: string }>`
  border-radius: 4px;
  height: 420px;
  width: 360px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.src});
`;

const MyCreativeQuickviewPreviewView = (
  props: MyCreativeQuickviewModalProps & {
    previewUrl: null | string;
    loading: boolean;
    isVideo: boolean;
    meta: VideoMeta | null;
    // temporary fix for the video player
    onClickForVideo?: () => void;
  }
) => {
  const { loading, isVideo, previewUrl, meta, onClickForVideo } = props;

  const isPublicVideo = !!onClickForVideo;
  const isPhoto = !isVideo && !isPublicVideo;
  const showPreview = Boolean(!loading && previewUrl);

  return (
    <PreviewContentWrapper onClick={onClickForVideo} clickable={isPublicVideo}>
      {loading && <LoadingSpinner />}
      {showPreview && (
        <>
          {isPhoto && <ThumbnailWrapper src={previewUrl as string} />}
          {isVideo && !isPublicVideo && <VideoPlayer meta={meta} />}
          {isPublicVideo && <div>Click to view on Facebook</div>}
        </>
      )}
    </PreviewContentWrapper>
  );
};

export default MyCreativeQuickviewPreviewView;
