import { CreativeType } from 'types/components/creatives';
import { CarouselIcon, ImageIcon, VideoIcon } from '@innovationdepartment/proxima-ui';
import { FilterValues } from './TopAds/types';

export const creativeTypeTag = {
  [CreativeType.PHOTO]: { label: 'Static', icon: <ImageIcon /> },
  [CreativeType.VIDEO]: { label: 'Video', icon: <VideoIcon /> },
  [CreativeType.CAROUSEL]: { label: 'Carousel', icon: <CarouselIcon /> },
};

// keeping these just in case we need to add tab functionality back to Top Ads later
export const AVAILABLE_TABS: { key: FilterValues['tab']; label: string }[] = [
  {
    key: 'all',
    label: 'Overall',
  },
  {
    key: 'roas',
    label: 'ROAS',
  },
  {
    key: 'ctr',
    label: 'CTR',
  },
];
