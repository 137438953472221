import styled from '@emotion/styled';
import { colors, Text } from '@innovationdepartment/proxima-ui';
import ModalBase from 'ui/ModalBase';
import { MyCreativeQuickviewModalProps } from './types';
import MyCreativeQuickviewPreview from './MyCreative.Quickview.Preview.Container';
import MyCreativeQuickviewAdDetails from './MyCreative.Quickview.AdDetails';

const MainContentWrapper = styled.div`
  border-top: 1px solid ${colors.neutral10};
  max-width: 932px;
  display: flex;
`;

const MyCreativeQuickviewModal = (props: MyCreativeQuickviewModalProps) => {
  const { open, onClose } = props;

  return (
    <ModalBase
      allowCloseOnClickOutside
      variant="default"
      title={
        <Text variant="h4" isTermina={false}>
          Ad Quickview
        </Text>
      }
      open={open}
      onClose={onClose}
    >
      <MainContentWrapper>
        <MyCreativeQuickviewPreview {...props} />
        <MyCreativeQuickviewAdDetails {...props} />
      </MainContentWrapper>
    </ModalBase>
  );
};

export default MyCreativeQuickviewModal;
