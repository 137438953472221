import styled from '@emotion/styled';
import { colors, MagicGear, Text } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  gap: 8px;
  align-self: stretch;
  position: fixed;
  width: 100%;
  background-color: ${colors.white};
  height: 100%;
  top: 69px;
  z-index: 4;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  text-align: center;
  position: fixed;
  top: 260px;
`;

const ImageContainer = styled.div`
  width: 432px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const MyCreativeNoData = () => (
  <Container>
    <ContentContainer>
      <ImageContainer>
        <MagicGear />
      </ImageContainer>
      <InnerContainer>
        <Text variant="h3" isTermina={false}>
          We&apos;re analyzing your creative performance...
        </Text>
        <Text variant="h4" color="neutral70" isTermina={false}>
          We&apos;ll let you know when your insights are ready to review
        </Text>
      </InnerContainer>
    </ContentContainer>
  </Container>
);

export default MyCreativeNoData;
