import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import { DatePicker, MetaNoBorder, Text, colors } from '@innovationdepartment/proxima-ui';

const SubheaderContainer = styled.div`
  background-color: ${colors.neutral5};
  border-bottom: 1px solid ${colors.neutral20};
  display: flex;
  flex-direction: row;
  height: 64px;
  padding: 0px 32px;
  justify-content: space-between;
  align-items: center;
`;

const SubheaderDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const SubheaderTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const SocialChannelTab = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  padding: 8px;

  > svg {
    height: 20px;
    width: 20px;
  }
`;

type InsightsHeaderProps = {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  onDateRangeSelect: PropsOf<typeof DatePicker>['onDateRangeSelect'];
};

const MyCreativeSubheader = (props: InsightsHeaderProps) => {
  const { dateRange, onDateRangeSelect } = props;

  return (
    <SubheaderContainer>
      <SubheaderTabs>
        <SocialChannelTab>
          <MetaNoBorder />
          <Text color="neutral90" variant="body1">
            Meta Insights
          </Text>
        </SocialChannelTab>
      </SubheaderTabs>
      <SubheaderDetails>
        <DatePicker
          dateRange={{
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }}
          onDateRangeSelect={onDateRangeSelect}
          withRange
          showSelectWithDateRange={false}
          disableFutureDates
        />
      </SubheaderDetails>
    </SubheaderContainer>
  );
};

export default MyCreativeSubheader;
