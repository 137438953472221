import styled from '@emotion/styled';
import { MyCreativeTilesTileProps } from '../../types';
import QuintilePill from '../QuintilePill';
import { Text } from '@innovationdepartment/proxima-ui';

const MetricsRow = styled.div`
  display: flex;
  height: 24px;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  > span:first-of-type {
    width: 110px;
  }

  > span:nth-of-type(2) {
    width: 50px;
  }
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
`;

const MyCreativeTilesTileMetrics = (props: Pick<MyCreativeTilesTileProps, 'ad'>) => {
  const { ad } = props;

  const roasDisplayValue = ad.purchaseRoas ? `${ad.purchaseRoas?.toFixed(2)}X` : '--';
  const ctrDisplayValue = ad.ctr ? `${ad.ctr?.toFixed(2)}%` : '--';

  return (
    <MetricsContainer>
      <MetricsRow>
        <Text variant="tag1Semibold">ROAS</Text>
        <Text variant="body2Semibold">{roasDisplayValue}</Text>
        <QuintilePill quintile={ad.purchaseRoasBrandIdQuentile} />
      </MetricsRow>
      <MetricsRow>
        <Text variant="tag1Semibold">CTR</Text>
        <Text variant="body2Semibold">{ctrDisplayValue}</Text>
        <QuintilePill quintile={ad.ctrBrandIdQuentile} />
      </MetricsRow>
    </MetricsContainer>
  );
};

export default MyCreativeTilesTileMetrics;
