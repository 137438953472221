import styled from '@emotion/styled';
import { TopAdsViewProps } from '../../types';
import { Button, colors, CustomCard, PrivacyOnSmall, Text } from '@innovationdepartment/proxima-ui';
import InfiniteScroll from 'ui/InfiniteScroll';
import { CATEGORIES } from 'constants/categories';

const BodyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & .tile-button {
    align-self: flex-start;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const CustomButton = styled(Button)`
  max-width: 48px;
  margin-top: 24px;
  padding: 4px 12px;
  min-width: unset;
  border-color: ${colors.neutral10};
`;

type TopAdsTilesTileProps = {
  ad: TopAdsViewProps['ads'][number];
  thumbnail: string;
  shouldShowInfiniteScroll: boolean;
  onTileClick: TopAdsViewProps['onTileClick'];
  onFetchNext: TopAdsViewProps['onFetchNext'];
  tag: { label: string; icon: JSX.Element } | undefined;
};

const TopAdsTilesTile = (props: TopAdsTilesTileProps) => {
  const { ad, thumbnail, shouldShowInfiniteScroll, tag, onTileClick, onFetchNext } = props;

  /* TODO(Jenky): to add when the functionality is brought up from Product */
  const onButtonClick = () => {};

  return (
    <>
      <CustomCard
        size="tile2"
        onClick={() => onTileClick(ad)}
        bodyContent={
          <BodyContentWrapper>
            <TitleWrapper>
              <Text variant="tag1Semibold">{ad.brand?.name}</Text>
              {ad?.category && (
                <Text color="neutral70" variant="body2Medium">
                  {CATEGORIES[ad.category]}
                </Text>
              )}
            </TitleWrapper>
            <CustomButton
              label={undefined}
              onClick={onButtonClick}
              color="secondary"
              variant="outlined"
            >
              <PrivacyOnSmall />
            </CustomButton>
          </BodyContentWrapper>
        }
        label={undefined}
        headerProps={{
          heroUrl: thumbnail,
          tag,
        }}
      />
      {shouldShowInfiniteScroll && <InfiniteScroll onInfiniteScroll={onFetchNext} />}
    </>
  );
};

export default TopAdsTilesTile;
